<template>
    <div id="subtenancy">
        <div class="logo">
         <img src="../assets/image/d.png" alt="">
         <div class="title">转借</div>
      </div>
      <div class="panel">
           <van-search placeholder="请输入被转借人的借阅证号" v-model="toReaderCard" />
        </div>
         <div >
            <van-button size="small" type="info" style="width:50%;margin-top:20px" @click="tosubtenancy">转借</van-button>
        </div>
    </div>
</template>
<script>
import { underlease} from "@/api/goods.js";
export default {
    data(){
        return {
            bookinfo:{},
            toReaderCard:'',
            renewinfo:{
             title:"",
             result:"",
            callnum:"",
            renewTime:"",
            borrowTime:"",
            }
        }
    },
   created(){
        this.bookinfo=this.$route.params
        console.log(this.bookinfo);
    },
    methods:{
        tosubtenancy(){
            if(!this.toReaderCard){
              this.$notify({
              message: '请输入被转借人的借阅证号',
              duration: 2000,
              background: '#f44'
            });
            }else{
                underlease({
                    barcode:this.bookinfo.barcode,
                    toReaderCard:this.toReaderCard
                }).then(res=>{
                    console.log(res.data);
                    this.$toast({
                    message:res.data.code==200?res.data.data.data.message:res.data.data.data.message,
                    type:res.data.code==200?"success":'fail',
                    onClose:()=>{
                        if(res.data.code==200){
                            console.log(123);
                            // this.$router.push({
                            // name:'Renew'
                        // })
                        }
                    }
                  })
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
 #subtenancy{
  width: 100%;
  height: 100%;
    .logo{
     margin-top:50px;
     width: 100%;
     height: 200px;
     .title{
       text-align: center;
       width: 100%;
       font-size: 20px;
     }
  }
  .panel{
      .title{
          font-size: 20px;
          text-align: left;
           margin-left: 25px;
          color:rgba($color: #28a8e493, $alpha: 1.0);
           span{
               font-size: 16px;
               color:rgba($color: #0d6d1a93, $alpha: 1.0)
           }
      }
      .info{
          p{
              font-size: 12px;
              text-align: left;
              margin-left: 30px;
              line-height: 12px;
              color:rgba($color: #0d6d1a93, $alpha: 1.0)
          }
      }
  }
}
</style>